import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header"
        style={{ marginBottom: "90px" }}
      >
        <div className="container">
          <div
            className="d-flex flex-column justify-content-center"
            style={{ minHeight: "300px" }}
          >
            <h3 className="display-4 text-white text-uppercase">About Us</h3>
            <div className="d-inline-flex text-white">
              <p className="m-0 text-uppercase">
                <Link className="text-white" t="/">
                  Home
                </Link>
              </p>
              <i className="fa fa-angle-double-right pt-1 px-3" />
              <p className="m-0 text-uppercase">About Us</p>
            </div>
          </div>
        </div>
      </div>
      {/* About Start */}
      <div className="container-fluid ">
        <div className="container ">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <img
                className="img-fluid rounded mb-4 mb-lg-0"
                src="img/about 1.jpg"
                alt=""
              />
            </div>
            <div className="col-lg-6">
              <div className="text-left mb-4">
                <h5
                  className="text-primary text-uppercase mb-3"
                  style={{ letterSpacing: "5px" }}
                ></h5>
                <h1> About Us</h1>
              </div>

              <p>
                Welcome to the dynamic world of {companyname}, your all-in-one
                logistics partner. Headquartered in the vibrant city of Mumbai,
                India, we proudly offer a diverse range of specialized services,
                encompassing transportation, warehousing, customs clearance, and
                both air and marine freight solutions.
              </p>

              <p>
                Our unwavering commitment to delivering consistent customer
                satisfaction is driven by our extensive expertise, skills, and
                resources. Fueled by our dedication to excellence and innovative
                problem-solving, we are rapidly expanding and leading the way as
                NVOCC agents and goods forwarders.
              </p>

              <p>
                We view the challenges faced by your company as opportunities
                and privileges. By joining hands with us, you can seamlessly
                overcome geographical barriers and logistical complexities.
                Introducing Ironbark Enterprises Logistics, your partner in
                navigating the ever-evolving world of cargo solutions.
              </p>

            
            </div>
          </div>
        </div>
      </div>
      {/* About End */}
            {/* Courses Start */}
            <div className="container-fluid pt-5">
        <div className="container py-5">
          <div className="text-center mb-5">
            <h5
              className="text-primary text-uppercase mb-3"
              style={{ letterSpacing: "5px" }}
            >
              Services
            </h5>
            <h1>Our Freight Solutions</h1>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="rounded overflow-hidden mb-2">
                <img
                  className="img-fluid"
                  src="img/Sea Transportation.jpg"
                  alt=""
                />
                <div className="bg-secondary p-4">
                  <Link className="h5" to="/Sea">
                    Sea Transportation
                  </Link>
                  <div className="border-top mt-4 pt-4">
                    <div className="d-flex justify-content-between">
                      <small className="m-0">
                        {" "}
                        Our state-of-the-art solutions provide access to your
                        sales and procurement markets wherever your shipments
                        need to
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="rounded overflow-hidden mb-2">
                <img
                  className="img-fluid"
                  src="img/Railway Transportation.jpg"
                  alt=""
                />
                <div className="bg-secondary p-4">
                  <Link className="h5" to="/SRailwayea">
                    Railway Transportation
                  </Link>
                  <div className="border-top mt-4 pt-4">
                    <div className="d-flex justify-content-between">
                      <small className="m-0">
                        {" "}
                        Our land transport services are meticulously tailored to
                        meet the exacting needs of our clients. These services
                        not
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="rounded overflow-hidden mb-2">
                <img
                  className="img-fluid"
                  src="img/Container Transportation.jpg"
                  alt=""
                />
                <div className="bg-secondary p-4">
                  <Link className="h5" to="/Container">
                    Container Transportation
                  </Link>
                  <div className="border-top mt-4 pt-4">
                    <div className="d-flex justify-content-between">
                      <small className="m-0">
                        Our transportation services encompass a comprehensive
                        range of offerings tailored to meet your specific needs:
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="rounded overflow-hidden mb-2">
                <img
                  className="img-fluid"
                  src="img/Multimodal Transportation.jpg"
                  alt=""
                />
                <div className="bg-secondary p-4">
                  <Link className="h5" to="/Multimodal">
                    Multimodal Transportation
                  </Link>
                  <div className="border-top mt-4 pt-4">
                    <div className="d-flex justify-content-between">
                      <small className="m-0">
                        {" "}
                        When transportation is seamlessly managed by a single
                        organization, your company can unlock substantial time
                        and
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="rounded overflow-hidden mb-2">
                <img
                  className="img-fluid"
                  src="img/Freight Forwarding.jpg"
                  alt=""
                />
                <div className="bg-secondary p-4">
                  <Link className="h5" to="/Freight">
                    Freight Forwarding
                  </Link>
                  <div className="border-top mt-4 pt-4">
                    <div className="d-flex justify-content-between">
                      <small className="m-0">
                        {" "}
                        Our unloading railroad transport services encompass a
                        range of critical tasks to ensure a seamless and
                        efficient process:
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="rounded overflow-hidden mb-2">
                <img className="img-fluid" src="img/Trading.jpg" alt="" />
                <div className="bg-secondary p-4">
                  <Link className="h5" to="/Trading">
                    Trading
                  </Link>
                  <div className="border-top mt-4 pt-4">
                    <div className="d-flex justify-content-between">
                      <small className="m-0">
                        {" "}
                        {companyname} stands as a premier trading service
                        provider with its headquarters situated in the bustling
                        city of
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Courses End */}

      <Footer />
    </>
  );
}
