import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header"
        style={{ marginBottom: "90px" }}
      >
        <div className="container">
          <div
            className="d-flex flex-column justify-content-center"
            style={{ minHeight: "300px" }}
          >
            <h3 className="display-4 text-white text-uppercase">
              Container Transportation
            </h3>
            <div className="d-inline-flex text-white">
              <p className="m-0 text-uppercase">
                <Link className="text-white" t="/">
                  Home
                </Link>
              </p>
              <i className="fa fa-angle-double-right pt-1 px-3" />
              <p className="m-0 text-uppercase">Container Transportation</p>
            </div>
          </div>
        </div>
      </div>
      {/* Detail Start */}
      <div className="container-fluid py-5">
        <div className="container ">
          <div className="row">
            <div className="col-lg-8">
              <div className="mb-5">
                <h1 className="mb-5">Container Transportation</h1>
                <img
                  className="img-fluid rounded w-100 mb-4"
                  src="img/Container Transportation.jpg"
                  alt="Image"
                />
                <p>
                  <p>
                    Our transportation services encompass a comprehensive range
                    of offerings tailored to meet your specific needs:
                  </p>

                  <ol>
                    <li>
                      <strong>Optimized Container Transportation:</strong> We
                      excel in managing all aspects of container transportation,
                      meticulously optimizing parameters such as freight rates
                      and container and terminal usage, ensuring cost-effective
                      and efficient logistics solutions.
                    </li>

                    <li>
                      <strong>Door-to-Door Transportation:</strong> Our
                      "door-to-door" services provide end-to-end transportation
                      solutions, seamlessly connecting the origin and
                      destination points, taking the hassle out of logistics for
                      you.
                    </li>

                    <li>
                      <strong>Complex Multimodal Services:</strong> We
                      specialize in providing complex multimodal transportation
                      services, seamlessly integrating various modes of
                      transport to ensure your cargo reaches its destination
                      efficiently.
                    </li>

                    <li>
                      <strong>Information Support:</strong> Stay in control with
                      our information support services, allowing you to track
                      and manage containers in real-time, providing you with
                      valuable insights and control over your shipments.
                    </li>

                    <li>
                      <strong>Strategic Partnerships:</strong> We have
                      established strong contractual relationships with leading
                      container lines, ensuring you have access to the best
                      options and routes for your container transportation
                      needs.
                    </li>
                  </ol>
                </p>
              </div>
            </div>
            <div className="col-lg-4 mt-5 mt-lg-0">
              <div className="mb-5">
                <h3
                  className="text-uppercase mb-4"
                  style={{ letterSpacing: "5px" }}
                >
                  Categories
                </h3>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                    <Link to="/Sea" className="text-decoration-none h6 m-0">
                      Sea Transportation
                    </Link>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                    <Link to="/Railway" className="text-decoration-none h6 m-0">
                      Railway Transportation
                    </Link>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                    <Link
                      to="/Container"
                      className="text-decoration-none h6 m-0"
                    >
                      Container Transportation
                    </Link>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                    <Link
                      to="/Multimodal"
                      className="text-decoration-none h6 m-0"
                    >
                      Multimodal Transportation
                    </Link>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                    <Link to="/Freight" className="text-decoration-none h6 m-0">
                      Freight Forwarding
                    </Link>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                    <Link to="/Trading" className="text-decoration-none h6 m-0">
                      Trading
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Detail End */}

      <Footer />
    </>
  );
}
