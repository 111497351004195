import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header"
        style={{ marginBottom: "90px" }}
      >
        <div className="container">
          <div
            className="d-flex flex-column justify-content-center"
            style={{ minHeight: "300px" }}
          >
            <h3 className="display-4 text-white text-uppercase">
              Railway Transportation
            </h3>
            <div className="d-inline-flex text-white">
              <p className="m-0 text-uppercase">
                <Link className="text-white" t="/">
                  Home
                </Link>
              </p>
              <i className="fa fa-angle-double-right pt-1 px-3" />
              <p className="m-0 text-uppercase">Railway Transportation</p>
            </div>
          </div>
        </div>
      </div>
      {/* Detail Start */}
      <div className="container-fluid py-5">
        <div className="container ">
          <div className="row">
            <div className="col-lg-8">
              <div className="mb-5">
                <h1 className="mb-5">Railway Transportation</h1>
                <img
                  className="img-fluid rounded w-100 mb-4"
                  src="img/Railway Transportation.jpg"
                  alt="Image"
                />
                <p>
                  <p>
                    Our land transport services are meticulously tailored to
                    meet the exacting needs of our clients. These services not
                    only offer reliability in budgeting but also ensure the
                    efficient and transparent movement of your goods by clearly
                    defining transit timelines.
                  </p>

                  <p>
                    Guided by our commitment to care and excellence, we take
                    utmost care in assuring the safe transportation of your
                    products. We achieve this through our dedicated fleet of
                    containers and trailers, guaranteeing the pristine condition
                    of your items with prompt and dependable services.
                  </p>

                  <p>
                    At the heart of our operations is a strong dedication to
                    prioritize the safety of your cargo. We are proud to be a
                    client-centric service provider, putting your cargo's safety
                    and satisfaction at the forefront of everything we do.
                  </p>
                </p>
              </div>
            </div>
            <div className="col-lg-4 mt-5 mt-lg-0">
              <div className="mb-5">
                <h3
                  className="text-uppercase mb-4"
                  style={{ letterSpacing: "5px" }}
                >
                  Categories
                </h3>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                    <Link to="/Sea" className="text-decoration-none h6 m-0">
                      Sea Transportation
                    </Link>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                    <Link to="/Railway" className="text-decoration-none h6 m-0">
                      Railway Transportation
                    </Link>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                    <Link
                      to="/Container"
                      className="text-decoration-none h6 m-0"
                    >
                      Container Transportation
                    </Link>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                    <Link
                      to="/Multimodal"
                      className="text-decoration-none h6 m-0"
                    >
                      Multimodal Transportation
                    </Link>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                    <Link to="/Freight" className="text-decoration-none h6 m-0">
                      Freight Forwarding
                    </Link>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                    <Link to="/Trading" className="text-decoration-none h6 m-0">
                      Trading
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Detail End */}

      <Footer />
    </>
  );
}
