import { Outlet, Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import $ from "jquery";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Header = (
  {
    /*  useEffect(() => {
    // Add event listener to disable right-click
    document.addEventListener('contextmenu', handleRightClick);
    
    // Cleanup: Remove event listener when component unmounts
    return () => {
      document.removeEventListener('contextmenu', handleRightClick);
    };
  }, []);

  // Function to handle the right-click event
  const handleRightClick = (e) => {
    e.preventDefault(); // Prevent the default context menu
  };
*/
  }
) => {
  return (
    <>
      <div className="container-fluid d-none d-lg-block">
        <div className="row align-items-center py-2 px-xl-5">
          <div className="col-lg-4">
            <Link to className="text-decoration-none">
              <h4>
            <img src="img/logo.png" alt="logo" style={{height:'90px'}}></img> {companyname}</h4>
            </Link>
          </div>
          <div className="col-lg-3 text-right">
            <div className="d-inline-flex align-items-center">
              <i className="fa fa-2x fa-map-marker-alt text-primary mr-3" />
              <div className="text-left">
                <h6 className="font-weight-semi-bold mb-1">Our Office</h6>
                <small>Navi Mumbai, Sanpada-Washi</small>
              </div>
            </div>
          </div>
          <div className="col-lg-2 text-right">
            <div className="d-inline-flex align-items-center">
              <i className="fa fa-2x fa-envelope text-primary mr-3" />
              <div className="text-left">
                <h6 className="font-weight-semi-bold mb-1">Email Us</h6>
                <small>{companyemail}</small>
              </div>
            </div>
          </div>
          <div className="col-lg-3 text-right">
            <div className="d-inline-flex align-items-center">
              <i className="fa fa-2x fa-phone text-primary mr-3" />
              <div className="text-left">
                <h6 className="font-weight-semi-bold mb-1">Call Us</h6>
                <small>{companynumber}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Topbar End */}
      {/* Navbar Start */}
      <div className="container-fluid">
        <div className="row border-top px-xl-5">
         
          <div className="col-lg-12">
            <nav className="navbar navbar-expand-lg bg-light navbar-light py-3 py-lg-0 px-0">
              <Link to className="text-decoration-none d-block d-lg-none">
                <h1 className="m-0">
                  <img src="img/logo.png" alt="logo" style={{height:'120px'}}></img>
                </h1>
              </Link>
              <button
                type="button"
                className="navbar-toggler"
                data-toggle="collapse"
                data-target="#navbarCollapse"
              >
                <span className="navbar-toggler-icon" />
              </button>
              <div
                className="collapse navbar-collapse justify-content-between"
                id="navbarCollapse"
              >
                <div className="navbar-nav py-0">
                  <Link to="/Home" className="nav-item nav-link active">
                    Home
                  </Link>
                  <Link to="/About" className="nav-item nav-link active">
                    Company Profile
                  </Link>
                  <div className="nav-item dropdown">
                    <Link
                      to="#"
                      className="nav-link dropdown-toggle active"
                      data-toggle="dropdown"
                    >
                      Freight Solutions
                    </Link>
                    <div className="dropdown-menu rounded-0 m-0">
                      <Link to="/Sea" className="dropdown-item">
                      Sea Transportation
                      </Link>
                      <Link to="/Railway" className="dropdown-item">
                      Railway Transportation 
                      </Link>
                      <Link to="/Container" className="dropdown-item">
                      Container Transportation 
                      </Link>
                      <Link to="/Multimodal" className="dropdown-item">
                      Multimodal Transportation 
                      </Link>
                      <Link to="/Freight" className="dropdown-item">
                      Freight Forwarding
                      </Link>
                      <Link to="/Trading" className="dropdown-item">
                      Trading 
                      </Link>
                    </div>
                  </div>
                  <div className="nav-item dropdown">
                    <Link
                      to="#"
                      className="nav-link dropdown-toggle active"
                      data-toggle="dropdown"
                    >
                      Trading Solutions
                    </Link>
                    <div className="dropdown-menu rounded-0 m-0">
                   
                      <Link to="/Trade" className="dropdown-item">
                      Automotive & Aerospace 
                      </Link>
                      <Link to="/Trade" className="dropdown-item">
                      Fashion 
                      </Link>
                      <Link to="/Trade" className="dropdown-item">
                      Food 
                      </Link>
                      <Link to="/Trade" className="dropdown-item">
                    Furniture 
                      </Link>
                      <Link to="/Trade" className="dropdown-item">
                      Chemical Industry 
                      </Link>
                       <Link to="/Trade" className="dropdown-item">
                       Mining 
                      </Link>
                    </div>
                  </div>
                  <Link to="/Contact" className="nav-item nav-link active">
                    Contact Us
                  </Link>
                </div>
                <Link
                  className="btn btn-primary py-2 px-4 ml-auto d-none d-lg-block"
                  to="/Getquote"
                >
                  Request For Quote
                </Link>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
