import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header"
        style={{ marginBottom: "90px" }}
      >
        <div className="container">
          <div
            className="d-flex flex-column justify-content-center"
            style={{ minHeight: "300px" }}
          >
            <h3 className="display-4 text-white text-uppercase">Trading</h3>
            <div className="d-inline-flex text-white">
              <p className="m-0 text-uppercase">
                <Link className="text-white" t="/">
                  Home
                </Link>
              </p>
              <i className="fa fa-angle-double-right pt-1 px-3" />
              <p className="m-0 text-uppercase">Trading</p>
            </div>
          </div>
        </div>
      </div>
      {/* Detail Start */}
      <div className="container-fluid py-5">
        <div className="container ">
          <div className="row">
            <div className="col-lg-8">
              <div className="mb-5">
                <h1 className="mb-5">Trading</h1>
                <img
                  className="img-fluid rounded w-100 mb-4"
                  src="img/Trading.jpg"
                  alt="Image"
                />
                <p>
                  <p>
                    {companyname} stands as a premier trading service provider
                    with its headquarters situated in the bustling city of
                    Mumbai. Our expertise spans across a wide spectrum of
                    services, primarily centered around the import and export of
                    an array of products. Over the years, we have garnered a
                    reputation for excellence, built on the foundational
                    principles of quality, promptness, and competitive pricing.
                  </p>

                  <p>
                    Quality is at the core of our operations. We are dedicated
                    to ensuring that the products we trade meet the highest
                    standards, providing our clients with confidence in the
                    reliability and superiority of our offerings. Our stringent
                    quality control measures and commitment to sourcing
                    top-notch goods from around the world have earned us the
                    trust of countless customers.
                  </p>

                  <p>
                    Promptness is another hallmark of our business. We
                    understand the importance of timeliness in the world of
                    trade, and our streamlined processes and efficient logistics
                    ensure that products reach their destinations promptly. This
                    commitment to swift and reliable delivery sets us apart as a
                    trading partner you can depend on. Moreover, our dedication
                    to competitive pricing ensures that our clients receive
                    exceptional value for their investments, making us a
                    preferred choice in the industry.
                  </p>
                  <p>
                    Our comprehensive trading services encompass a diverse range
                    of industries and sectors, catering to the unique needs of
                    each. We specialize in:
                  </p>

                  <ul>
                    <li>
                      <strong>Automotive & Aerospace:</strong> Serving as a
                      trusted partner in the supply chain, we facilitate the
                      seamless import and export of automotive and aerospace
                      components, ensuring the smooth functioning of these
                      critical industries.
                    </li>

                    <li>
                      <strong>Fashion:</strong> In the dynamic world of fashion,
                      we excel in the international trade of textiles, apparel,
                      and accessories, enabling businesses to access global
                      markets and stay ahead of style trends.
                    </li>

                    <li>
                      <strong>Food:</strong> With a focus on food safety and
                      quality, we specialize in the import and export of a wide
                      range of food products, ensuring that the world's cuisines
                      reach your table with freshness and flavor.
                    </li>

                    <li>
                      <strong>Furniture:</strong> Our trading services extend to
                      the furniture industry, facilitating the movement of
                      exquisite furnishings and interior decor items to enhance
                      living and working spaces worldwide.
                    </li>

                    <li>
                      <strong>Chemical Industry:</strong> We play a vital role
                      in the chemical sector, managing the import and export of
                      chemicals and related products, contributing to the growth
                      and innovation of this critical industry.
                    </li>

                    <li>
                      <strong>Mining:</strong> In the mining industry, we
                      support the transfer of valuable minerals and resources,
                      ensuring that these materials reach their destinations
                      efficiently and sustainably.
                    </li>
                  </ul>
                </p>
              </div>
            </div>
            <div className="col-lg-4 mt-5 mt-lg-0">
              <div className="mb-5">
                <h3
                  className="text-uppercase mb-4"
                  style={{ letterSpacing: "5px" }}
                >
                  Categories
                </h3>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                    <Link to="/Sea" className="text-decoration-none h6 m-0">
                      Sea Transportation
                    </Link>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                    <Link to="/Railway" className="text-decoration-none h6 m-0">
                      Railway Transportation
                    </Link>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                    <Link
                      to="/Container"
                      className="text-decoration-none h6 m-0"
                    >
                      Container Transportation
                    </Link>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                    <Link
                      to="/Multimodal"
                      className="text-decoration-none h6 m-0"
                    >
                      Multimodal Transportation
                    </Link>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                    <Link to="/Freight" className="text-decoration-none h6 m-0">
                      Freight Forwarding
                    </Link>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                    <Link to="/Trading" className="text-decoration-none h6 m-0">
                      Trading
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Detail End */}

      <Footer />
    </>
  );
}
