import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Contact() {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleFnameChange = (e) => {
    setFname(e.target.value);
  };
  const handlelnameChange = (e) => {
    setLname(e.target.value);
  };
  const handleemailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlesubjectChange = (e) => {
    setSubject(e.target.value);
  };
  const handlemessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />
  {/* Header Start */}
  <div className="container-fluid page-header" style={{marginBottom: '90px'}}>
          <div className="container">
            <div className="d-flex flex-column justify-content-center" style={{minHeight: '300px'}}>
              <h3 className="display-4 text-white text-uppercase">Contact</h3>
              <div className="d-inline-flex text-white">
                <p className="m-0 text-uppercase"><Link  className="text-white" t="/">Home</Link></p>
                <i className="fa fa-angle-double-right pt-1 px-3" />
                <p className="m-0 text-uppercase">Contact</p>
              </div>
            </div>
          </div>
        </div>
        {/* Header End */}
        {/* Contact Start */}
        <div className="container-fluid py-5">
          <div className="container py-5">
            <div className="text-center mb-5">
              <h5 className="text-primary text-uppercase mb-3" style={{letterSpacing: '5px'}}>Contact</h5>
              <h1>Contact Us For Any Query</h1>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="contact-form bg-secondary rounded p-5">
                  <div id="success" />
                  <form
                  id="contactForm"
                  action="/php/thankyou-contact.php "
                  method="post"
                  onSubmit={(event) => handleSubmit(event)}
                >
                  <div className="row g-3">
                    <div className="col-md-6">
                      <div className="control-group">
                        <input
                          className="form-control border-0 p-4"
                          onChange={(event) => handleFnameChange(event)}
                          type="text"
                          id="fname"
                          name="fname"
                          value={fname}
                          placeholder="Enter your first name"
                          required
                        />
                       <p class="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="control-group">
                        <input
                          className="form-control border-0 p-4"
                          onChange={(event) => handlelnameChange(event)}
                          type="text"
                          id="lname"
                          name="lname"
                          value={lname}
                          placeholder="Enter your last name"
                          required
                        />
                     <p class="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="control-group">
                        <input
                          className="form-control border-0 p-4"
                          onChange={(event) => handleemailChange(event)}
                          type="email"
                          id="email"
                          name="email"
                          value={email}
                          placeholder="Enter your Email"
                          required
                        />
                       <p class="help-block text-danger"></p>
                      </div>
                    </div>{" "}
                    <div className="col-12">
                      <div className="control-group">
                        <input
                          className="form-control border-0 p-4"
                          onChange={(event) => handlesubjectChange(event)}
                          type="text"
                          id="subject"
                          name="subject"
                          value={subject}
                          placeholder="Enter subject"
                          required
                        />
                      <p class="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="control-group">
                        <textarea
                          className="form-control border-0 p-4"
                          id="message"
                          name="message"
                          rows={1}
                          placeholder="Enter Message"
                          defaultValue={""}
                          value={message}
                          onChange={(event) => handlemessageChange(event)}
                        />
                        <p class="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-12 text-center">
                      <button
                        className="btn btn-primary py-3 px-5"
                        type="submit"
                        defaultValue=""
                      >
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>
                <h5 style={{ color: "red" }}>{result}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Contact End */}
      <Footer />
    </>
  );
}
