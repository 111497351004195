import { useState } from "react";
import { useHistory } from "react-router-dom";
import Getquote from "../pages/Getquote";

import $ from "jquery";
import Header from "../components/Header";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Footer = () => {
  const [subemail, setSubemail] = useState("");
  const [result, setResult] = useState("");

  const handleSubemailChange = (e) => {
    setSubemail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      {/* Footer Start */}
      <div
        className="container-fluid bg-dark text-white py-5 px-sm-3 px-lg-5"
        style={{ marginTop: "90px" }}
      >
        <div className="row pt-5">
          <div className="col-lg-8 col-md-12">
            <div className="row">
              <div className="col-md-4 mb-5">
                <h5
                  className="text-primary text-uppercase mb-4"
                  style={{ letterSpacing: "5px" }}
                >
                  Get In Touch
                </h5>
                <p>
                  <i className="fa fa-map-marker-alt mr-2" />
                  {companyaddress}
                </p>
                <p>
                  <i className="fa fa-phone-alt mr-2" />
                 {companynumber}
                </p>
                <p>
                  <i className="fa fa-envelope mr-2" />
                  {companyemail}
                </p>
                
              </div>
              <div className="col-md-4 mb-5">
                <h5
                  className="text-primary text-uppercase mb-4"
                  style={{ letterSpacing: "5px" }}
                >
                  Our Courses
                </h5>
                <div className="d-flex flex-column justify-content-start">
                  <Link to="/Home" className="text-white mb-2">
                    <i className="fa fa-angle-right mr-2" />
                    Home
                  </Link>
                  <Link to="/About" className="text-white mb-2">
                    <i className="fa fa-angle-right mr-2" />
                    About Us
                  </Link>
                  <Link to="/Services" className="text-white mb-2">
                    <i className="fa fa-angle-right mr-2" />
                    Freight Services
                  </Link>
                  <Link to="/Trade" className="text-white mb-2">
                    <i className="fa fa-angle-right mr-2" />
                    Trading Services
                  </Link>
                  <Link to="/Contact" className="text-white mb-2">
                    <i className="fa fa-angle-right mr-2" />
                    Contact Us
                  </Link>
                  <Link to="/Getquote" className="text-white mb-2">
                    <i className="fa fa-angle-right mr-2" />
                    Request Quote
                  </Link>
                </div>
              </div>
              <div className="col-md-4 mb-5">
                <h5
                  className="text-primary text-uppercase mb-4"
                  style={{ letterSpacing: "5px" }}
                >
                  Our Services
                </h5>
                <div className="d-flex flex-column justify-content-start">
                  <Link to="/Sea" className="text-white mb-2">
                    <i className="fa fa-angle-right mr-2" />
                    Sea Transportation
                  </Link>
                  <Link to="/Railway" className="text-white mb-2">
                    <i className="fa fa-angle-right mr-2" />
                    Railway Transportation
                  </Link>
                  <Link to="/Container" className="text-white mb-2">
                    <i className="fa fa-angle-right mr-2" />
                    Container Transportation
                  </Link>
                  <Link to="/Multimodal" className="text-white mb-2">
                    <i className="fa fa-angle-right mr-2" />
                    Multimodal Transportation
                  </Link>
                  <Link to="/Freight" className="text-white mb-2">
                    <i className="fa fa-angle-right mr-2" />
                    Freight Forwarding
                  </Link>
                  <Link to="/Trading" className="text-white mb-2">
                    <i className="fa fa-angle-right mr-2" />
                    Trading
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 mb-5">
            <h5
              className="text-primary text-uppercase mb-4"
              style={{ letterSpacing: "5px" }}
            >
              Newsletter
            </h5>
            <p>
              Subscribe to our newsletter and stay up-to-date with the latest
              trends, news, and updates in the world of freight forwarding.
              Don't miss out on crucial insights that can help your business
              thrive.
            </p>
            <div className="w-100">
              <div className="input-group">
              <form
                    className="subscribe d-flex"
                    action="/php/thankyou-subscribe.php "
                    method="POST"
                    onSubmit={(event) => handleSubmit(event)}
                  >
                      <input
                        onChange={(event) => handleSubemailChange(event)}
                        id="subemail"
                        value={subemail}
                        className="form-control border-light"
                        type="email"
                        name="subemail"
                        style={{ padding: "30px" }}
                        placeholder="Enter your email "
                        required
                      />
                       <div className="input-group-append">
                  <button className="btn btn-primary px-4">Sign Up</button>
                </div>
                    
                  </form>
                  <h5 style={{ color: "red" }}>{result}</h5>
               
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container-fluid bg-dark text-white border-top py-4 px-sm-3 px-md-5"
        style={{ borderColor: "rgba(256, 256, 256, .1) !important" }}
      >
        <div className="row">
          <div className="col-lg-6 text-center text-md-left mb-3 mb-md-0">
            <p className="m-0 text-white">
              ©2023 <Link to="/">{companyname}</Link>. All Rights Reserved.
            </p>
          </div>
          <div className="col-lg-6 text-center text-md-right">
            <ul className="nav d-inline-flex">
              <li className="nav-item">
                <Link className="nav-link text-white py-0" to="/Privacy">
                  Privacy Policy
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-white py-0" to="/Terms">
                  Terms
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-white py-0" to="/Contact">
                  Contact
                </Link>
              </li>
            
            </ul>
          </div>
        </div>
      </div>
      {/* Footer End */}
    </>
  );
};

export default Footer;
