import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header"
        style={{ marginBottom: "90px" }}
      >
        <div className="container">
          <div
            className="d-flex flex-column justify-content-center"
            style={{ minHeight: "300px" }}
          >
            <h3 className="display-4 text-white text-uppercase">
              Trading Solutions
            </h3>
            <div className="d-inline-flex text-white">
              <p className="m-0 text-uppercase">
                <Link className="text-white" t="/">
                  Home
                </Link>
              </p>
              <i className="fa fa-angle-double-right pt-1 px-3" />
              <p className="m-0 text-uppercase">Trading Solutions</p>
            </div>
          </div>
        </div>
      </div>
      {/* About Start */}
      <div className="container-fluid ">
        <div className="container ">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <img
                className="img-fluid rounded mb-4 mb-lg-0"
                src="img/Automotive & Aerospace.jpg"
                alt=""
              />
            </div>
            <div className="col-lg-6">
              <div className="text-left mb-4">
                <h5
                  className="text-primary text-uppercase mb-3"
                  style={{ letterSpacing: "5px" }}
                ></h5>
                <h1>Automotive & Aerospace</h1>
              </div>

              <p>
                {companyname} is dedicated to providing personalized
                transportation and all-encompassing logistics services
                specifically designed to meet the unique needs of the automotive
                sector. Our unwavering commitment lies in adhering to the
                exacting standards of quality and service that our valued
                customers demand, ensuring their satisfaction at every turn.
              </p>

              <p>
                Whether it's the transportation of vehicles or the management of
                intricate supply chains, our dedication to excellence drives us
                to consistently deliver top-tier service that aligns perfectly
                with the ever-evolving automotive landscape.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid ">
        <div className="container ">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="text-left mb-4">
                <h5
                  className="text-primary text-uppercase mb-3"
                  style={{ letterSpacing: "5px" }}
                ></h5>
                <h1>Fashion</h1>
              </div>
              <p>
                Our commitment to providing tailor-made solutions is aimed at
                bolstering our customers' standing within the fashion industry,
                enhancing their competitive edge. We understand the unique
                challenges of the fashion sector and work diligently to empower
                our clients for greater success.
              </p>
            </div>
            <div className="col-lg-6">
              <img
                className="img-fluid rounded mb-4 mb-lg-0"
                src="img/Fashion.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      {/* About End */}
      {/* About Start */}
      <div className="container-fluid ">
        <div className="container ">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <img
                className="img-fluid rounded mb-4 mb-lg-0"
                src="img/Food.jpg"
                alt=""
              />
            </div>
            <div className="col-lg-6">
              <div className="text-left mb-4">
                <h5
                  className="text-primary text-uppercase mb-3"
                  style={{ letterSpacing: "5px" }}
                ></h5>
                <h1>Food</h1>
              </div>
              <p>
                We recognize the paramount mission of the food industry, which
                is to ensure the supply of safe and nourishing food to millions
                of individuals across the globe. In alignment with this vital
                goal, we pledge to consistently provide the essential resources
                for the specialized preservation, handling, and distribution of
                these products, all while upholding the most stringent standards
                of quality and service.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid ">
        <div className="container ">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="text-left mb-4">
                <h5
                  className="text-primary text-uppercase mb-3"
                  style={{ letterSpacing: "5px" }}
                ></h5>
                <h1>Furniture</h1>
              </div>
              <p>
                At {companyname}, our global network of offices is dedicated
                to ensuring the seamless transit of your furniture products from
                their source to their final destination. We understand the
                critical importance of delivering your furniture on time and in
                impeccable condition, meeting and exceeding your expectations at
                every step.
              </p>

              <p>
                In the ever-evolving landscape of Furniture Logistics, we stand
                at the forefront, equipped with cutting-edge solutions that span
                the globe. While every furniture manufacturer, importer, or
                retailer may have unique needs, logistics challenges are a
                common thread. These challenges manifest as transportation
                complexities, production delays, and global reach limitations.
                We specialize in addressing these industry-wide issues, offering
                tailored solutions that cater to the specific requirements of
                furniture businesses of all sizes.
              </p>
            </div>
            <div className="col-lg-6">
              <img
                className="img-fluid rounded mb-4 mb-lg-0"
                src="img/Furniture.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      {/* About End */}
      {/* About Start */}
      <div className="container-fluid ">
        <div className="container ">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <img
                className="img-fluid rounded mb-4 mb-lg-0"
                src="img/Chemical Industry.jpg"
                alt=""
              />
            </div>
            <div className="col-lg-6">
              <div className="text-left mb-4">
                <h5
                  className="text-primary text-uppercase mb-3"
                  style={{ letterSpacing: "5px" }}
                ></h5>
                <h1>Chemical Industry</h1>
              </div>
              <p>
                {companyname} is your trusted partner in providing
                personalized transportation and comprehensive integrated
                logistics solutions designed specifically for the chemical
                industry. We meticulously adhere to the industry's stringent
                quality and service standards, tailoring our approach to meet
                the unique needs of each subsector, from fine chemicals to
                consumer goods.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid ">
        <div className="container ">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="text-left mb-4">
                <h5
                  className="text-primary text-uppercase mb-3"
                  style={{ letterSpacing: "5px" }}
                ></h5>
                <h1>Mining</h1>
              </div>
              <p>
                Our proficient team, boasting extensive experience in the mining
                sector, empowers us to deliver comprehensive and efficient
                solutions while upholding the highest standards of safety and
                quality. We prioritize building trust with our clients by
                providing straightforward answers to intricate logistic
                dilemmas, ensuring seamless operations in the mining industry.
              </p>
            </div>
            <div className="col-lg-6">
              <img
                className="img-fluid rounded mb-4 mb-lg-0"
                src="img/Mining.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      {/* About End */}

      <Footer />
    </>
  );
}
