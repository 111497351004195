import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Home() {
  const [uname, setUname] = useState("");
  const [uemail, setUemail] = useState("");
  const [uphone, setUphone] = useState("");
  const [subject, setSubject] = useState("");
  const [uservice, setUservice] = useState("");

  const [umessage, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleUnameChange = (e) => {
    setUname(e.target.value);
  };
  const handleUemailChange = (e) => {
    setUemail(e.target.value);
  };
  const handleUphoneChange = (e) => {
    setUphone(e.target.value);
  };
  const handleuserviceChange = (e) => {
    setUservice(e.target.value);
  };

  const handlesubjectChange = (e) => {};

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />

      <section
        className="container-fluid p-0 pb-5 mb-5 w3-banner jarallax"
        style={{ paddingTop: "100px" }}
        id="home"
      >
        <video autoPlay loop muted>
          <source src="img/video.mp4"></source>
        </video>
      </section>

      {/* About Start */}
      <div className="container-fluid ">
        <div className="container ">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <img
                className="img-fluid rounded mb-4 mb-lg-0"
                src="img/about 1.jpg"
                alt=""
              />
            </div>
            <div className="col-lg-6">
              <div className="text-left mb-4">
                <h5
                  className="text-primary text-uppercase mb-3"
                  style={{ letterSpacing: "5px" }}
                ></h5>
                <h1> About Us</h1>
              </div>

              <p>
                Welcome to the dynamic world of {companyname}, your all-in-one
                logistics partner. Headquartered in the vibrant city of Mumbai,
                India, we proudly offer a diverse range of specialized services,
                encompassing transportation, warehousing, customs clearance, and
                both air and marine freight solutions.
              </p>

              <p>
                Our unwavering commitment to delivering consistent customer
                satisfaction is driven by our extensive expertise, skills, and
                resources. Fueled by our dedication to excellence and innovative
                problem-solving, we are rapidly expanding and leading the way as
                NVOCC agents and goods forwarders.
              </p>

              <p>
                We view the challenges faced by your company as opportunities
                and privileges. By joining hands with us, you can seamlessly
                overcome geographical barriers and logistical complexities.
                Introducing Ironbark Enterprises Logistics, your partner in
                navigating the ever-evolving world of cargo solutions.
              </p>

              <Link
                to="/About"
                className="btn btn-primary py-md-2 px-md-4 font-weight-semi-bold mt-2"
              >
                Learn More
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}
      {/* Category Start */}

      {/* Courses Start */}
      <div className="container-fluid pt-5">
        <div className="container py-5">
          <div className="text-center mb-5">
            <h5
              className="text-primary text-uppercase mb-3"
              style={{ letterSpacing: "5px" }}
            >
              Services
            </h5>
            <h1>Our Freight Solutions</h1>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="rounded overflow-hidden mb-2">
                <img
                  className="img-fluid"
                  src="img/Sea Transportation.jpg"
                  alt=""
                />
                <div className="bg-secondary p-4">
                  <Link className="h5" to="/Sea">
                    Sea Transportation
                  </Link>
                  <div className="border-top mt-4 pt-4">
                    <div className="d-flex justify-content-between">
                      <small className="m-0">
                        {" "}
                        Our state-of-the-art solutions provide access to your
                        sales and procurement markets wherever your shipments
                        need to
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="rounded overflow-hidden mb-2">
                <img
                  className="img-fluid"
                  src="img/Railway Transportation.jpg"
                  alt=""
                />
                <div className="bg-secondary p-4">
                  <Link className="h5" to="/SRailwayea">
                    Railway Transportation
                  </Link>
                  <div className="border-top mt-4 pt-4">
                    <div className="d-flex justify-content-between">
                      <small className="m-0">
                        {" "}
                        Our land transport services are meticulously tailored to
                        meet the exacting needs of our clients. These services
                        not
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="rounded overflow-hidden mb-2">
                <img
                  className="img-fluid"
                  src="img/Container Transportation.jpg"
                  alt=""
                />
                <div className="bg-secondary p-4">
                  <Link className="h5" to="/Container">
                    Container Transportation
                  </Link>
                  <div className="border-top mt-4 pt-4">
                    <div className="d-flex justify-content-between">
                      <small className="m-0">
                        Our transportation services encompass a comprehensive
                        range of offerings tailored to meet your specific needs:
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="rounded overflow-hidden mb-2">
                <img
                  className="img-fluid"
                  src="img/Multimodal Transportation.jpg"
                  alt=""
                />
                <div className="bg-secondary p-4">
                  <Link className="h5" to="/Multimodal">
                    Multimodal Transportation
                  </Link>
                  <div className="border-top mt-4 pt-4">
                    <div className="d-flex justify-content-between">
                      <small className="m-0">
                        {" "}
                        When transportation is seamlessly managed by a single
                        organization, your company can unlock substantial time
                        and
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="rounded overflow-hidden mb-2">
                <img
                  className="img-fluid"
                  src="img/Freight Forwarding.jpg"
                  alt=""
                />
                <div className="bg-secondary p-4">
                  <Link className="h5" to="/Freight">
                    Freight Forwarding
                  </Link>
                  <div className="border-top mt-4 pt-4">
                    <div className="d-flex justify-content-between">
                      <small className="m-0">
                        {" "}
                        Our unloading railroad transport services encompass a
                        range of critical tasks to ensure a seamless and
                        efficient process:
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="rounded overflow-hidden mb-2">
                <img className="img-fluid" src="img/Trading.jpg" alt="" />
                <div className="bg-secondary p-4">
                  <Link className="h5" to="/Trading">
                    Trading
                  </Link>
                  <div className="border-top mt-4 pt-4">
                    <div className="d-flex justify-content-between">
                      <small className="m-0">
                        {" "}
                        {companyname} stands as a premier trading service
                        provider with its headquarters situated in the bustling
                        city of
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Courses End */}
      {/* Registration Start */}
      <div
        className="container-fluid bg-registration py-5"
        style={{ margin: "90px 0" }}
      >
        <div className="container py-5">
          <div className="row align-items-center">
            <div className="col-lg-7 mb-5 mb-lg-0">
              <div className="mb-4">
                <h5
                  className="text-primary text-uppercase mb-3"
                  style={{ letterSpacing: "5px" }}
                >
                  Need Any Courses
                </h5>
                <h1 className="text-white">30% Off For New Students</h1>
              </div>
              <p className="text-white">
                Invidunt lorem justo sanctus clita. Erat lorem labore ea, justo
                dolor lorem ipsum ut sed eos, ipsum et dolor kasd sit ea justo.
                Erat justo sed sed diam. Ea et erat ut sed diam sea ipsum est
                dolor
              </p>
              <ul className="list-inline text-white m-0">
                <li className="py-2">
                  <i className="fa fa-check text-primary mr-3" />
                  Labore eos amet dolor amet diam
                </li>
                <li className="py-2">
                  <i className="fa fa-check text-primary mr-3" />
                  Etsea et sit dolor amet ipsum
                </li>
                <li className="py-2">
                  <i className="fa fa-check text-primary mr-3" />
                  Diam dolor diam elitripsum vero.
                </li>
              </ul>
            </div>
            <div className="col-lg-5">
              <div className="card border-0">
                <div className="card-header bg-light text-center p-4">
                  <h1 className="m-0">Request For Quote</h1>
                </div>
                <div className="card-body rounded-bottom bg-primary p-5">
                  <form
                    action="/php/getquoteHome.php"
                    method="post"
                    onSubmit={(event) => handleSubmit(event)}
                  >
                    <div className="form-group">
                      <input
                        className="form-control border-0 p-4"
                        value={uname}
                        onChange={(event) => handleUnameChange(event)}
                        type="text"
                        name="uname"
                        id="uname"
                        placeholder="Enter your Fullname"
                        required="Please Enter the Fullname"
                      />{" "}
                    </div>
                    <div className="form-group">
                      <input
                        className="form-control border-0 p-4"
                        value={uemail}
                        onChange={(event) => handleUemailChange(event)}
                        type="email"
                        name="uemail"
                        id="uemail"
                        placeholder="Enter Your Email"
                        required="Please Enter Your Email"
                      />{" "}
                    </div>
                    <div className="form-group">
                      <input
                        className="form-control border-0 p-4"
                        value={uphone}
                        onChange={(event) => handleUphoneChange(event)}
                        type="text"
                        name="uphone"
                        id="uphone"
                        placeholder="Enter Phone Number"
                        required="Please Enter Phone Number"
                      />
                    </div>
                    <div className="form-group">
                      <select
                        className="custom-select border-0 px-4"
                        name="uservice"
                        id="uservice"
                        value={uservice}
                        onChange={(event) => handleuserviceChange(event)}
                      >
                        <option selected>Service Required</option>
                        <option value="Air Freight Forwarding">
                          Air Freight Forwarding
                        </option>
                        <option value="Ocean Freight Forwarding">
                          Sea Freight Forwarding
                        </option>
                        <option value="Land Freight Forwarding">
                          Land Freight Forwarding
                        </option>
                      </select>
                    </div>
                    <div>
                      <button
                        className="btn btn-dark btn-block border-0 py-3"
                        type="submit"
                      >
                        Send Request
                      </button>
                    </div>
                  </form>
                  <h5 style={{ color: "red" }}>{result}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Registration End */}

      {/* Blog Start */}
      <div className="container-fluid py-5">
        <div className="container pt-5 pb-3">
          <div className="text-center mb-5">
            <h5
              className="text-primary text-uppercase mb-3"
              style={{ letterSpacing: "5px" }}
            >
              Trading Services
            </h5>
            <h1>Explore Our Trading Services</h1>
          </div>
          <div className="row pb-3">
            <div className="col-lg-4 mb-4">
              <div className="blog-item position-relative overflow-hidden rounded mb-2">
                <img
                  className="img-fluid"
                  src="img/Automotive & Aerospace.jpg"
                  alt=""
                />
                <Link
                  className="blog-overlay text-decoration-none"
                  to="/Trading"
                >
                  <h5 className="text-white mb-3">Automotive & Aerospace</h5>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="blog-item position-relative overflow-hidden rounded mb-2">
                <img className="img-fluid" src="img/Fashion.jpg" alt="" />
                <Link
                  className="blog-overlay text-decoration-none"
                  to="/Trading"
                >
                  <h5 className="text-white mb-3">Fashion</h5>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="blog-item position-relative overflow-hidden rounded mb-2">
                <img className="img-fluid" src="img/Food.jpg" alt="" />
                <Link
                  className="blog-overlay text-decoration-none"
                  to="/Trading"
                >
                  <h5 className="text-white mb-3">Food</h5>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="blog-item position-relative overflow-hidden rounded mb-2">
                <img className="img-fluid" src="img/Furniture.jpg" alt="" />
                <Link
                  className="blog-overlay text-decoration-none"
                  to="/Trading"
                >
                  <h5 className="text-white mb-3">Furniture</h5>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="blog-item position-relative overflow-hidden rounded mb-2">
                <img
                  className="img-fluid"
                  src="img/Chemical Industry.jpg"
                  alt=""
                />
                <Link
                  className="blog-overlay text-decoration-none"
                  to="/Trading"
                >
                  <h5 className="text-white mb-3">Chemical Industry</h5>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="blog-item position-relative overflow-hidden rounded mb-2">
                <img className="img-fluid" src="img/Mining.jpg" alt="" />
                <Link
                  className="blog-overlay text-decoration-none"
                  to="/Trading"
                >
                  <h5 className="text-white mb-3">Mining</h5>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Blog End */}
      <Footer />
    </>
  );
}
