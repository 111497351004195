import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header"
        style={{ marginBottom: "90px" }}
      >
        <div className="container">
          <div
            className="d-flex flex-column justify-content-center"
            style={{ minHeight: "300px" }}
          >
            <h3 className="display-4 text-white text-uppercase">
              Sea Transportation
            </h3>
            <div className="d-inline-flex text-white">
              <p className="m-0 text-uppercase">
                <Link className="text-white" t="/">
                  Home
                </Link>
              </p>
              <i className="fa fa-angle-double-right pt-1 px-3" />
              <p className="m-0 text-uppercase">Sea Transportation</p>
            </div>
          </div>
        </div>
      </div>
      {/* Detail Start */}
      <div className="container-fluid py-5">
        <div className="container ">
          <div className="row">
            <div className="col-lg-8">
              <div className="mb-5">
                <h1 className="mb-5">Sea Transportation</h1>
                <img
                  className="img-fluid rounded w-100 mb-4"
                  src="img/Sea Transportation.jpg"
                  alt="Image"
                />
                <p>
                  <p>
                    Our state-of-the-art solutions provide access to your sales
                    and procurement markets wherever your shipments need to
                    reach. Through our agreements with numerous carriers, we
                    function as a centralized logistics hub, simplifying the
                    management of all your goods as they move through the supply
                    chain.
                  </p>

                  <p>
                    We offer a wide range of services, including purchase order
                    management, shipment tracking, flexible and reliable
                    shipping schedules, and capacity assurance during peak
                    demand periods. With our comprehensive and tailored
                    solutions, you can effectively navigate the complexities of
                    shipping.
                  </p>

                  <p>Our personalized services encompass:</p>

                  <ul>
                    <li>Consolidation of cargo</li>
                    <li>FCL and LCL consolidation</li>
                    <li>Multimodal transportation by sea/air/road</li>
                    <li>Customs clearance and brokerage services</li>
                    <li>Cargo insurance services</li>
                    <li>
                      Pick-up, packing options, delivery, and distribution
                    </li>
                    <li>Complete documentation service</li>
                    <li>
                      Sensitive freight handling – Dry, Liquid, Bulk, and
                      Perishables
                    </li>
                  </ul>
                </p>
              </div>
            </div>
            <div className="col-lg-4 mt-5 mt-lg-0">
              <div className="mb-5">
                <h3
                  className="text-uppercase mb-4"
                  style={{ letterSpacing: "5px" }}
                >
                  Categories
                </h3>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                    <Link to="/Sea" className="text-decoration-none h6 m-0">
                      Sea Transportation
                    </Link>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                    <Link to="/Railway" className="text-decoration-none h6 m-0">
                      Railway Transportation
                    </Link>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                    <Link
                      to="/Container"
                      className="text-decoration-none h6 m-0"
                    >
                      Container Transportation
                    </Link>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                    <Link
                      to="/Multimodal"
                      className="text-decoration-none h6 m-0"
                    >
                      Multimodal Transportation
                    </Link>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                    <Link to="/Freight" className="text-decoration-none h6 m-0">
                      Freight Forwarding
                    </Link>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                    <Link to="/Trading" className="text-decoration-none h6 m-0">
                      Trading
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Detail End */}

      <Footer />
    </>
  );
}
