import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header"
        style={{ marginBottom: "90px" }}
      >
        <div className="container">
          <div
            className="d-flex flex-column justify-content-center"
            style={{ minHeight: "300px" }}
          >
            <h3 className="display-4 text-white text-uppercase">
              Freight Forwarding
            </h3>
            <div className="d-inline-flex text-white">
              <p className="m-0 text-uppercase">
                <Link className="text-white" t="/">
                  Home
                </Link>
              </p>
              <i className="fa fa-angle-double-right pt-1 px-3" />
              <p className="m-0 text-uppercase">Freight Forwarding</p>
            </div>
          </div>
        </div>
      </div>
      {/* Detail Start */}
      <div className="container-fluid py-5">
        <div className="container ">
          <div className="row">
            <div className="col-lg-8">
              <div className="mb-5">
                <h1 className="mb-5">Freight Forwarding</h1>
                <img
                  className="img-fluid rounded w-100 mb-4"
                  src="img/Freight Forwarding.jpg"
                  alt="Image"
                />
                <p>
                  <p>
                    Our unloading railroad transport services encompass a range
                    of critical tasks to ensure a seamless and efficient
                    process:
                  </p>

                  <ol>
                    <li>
                      <strong>Cargo Acceptance:</strong> Our dedicated team
                      accepts the cargo on behalf of the recipient organization,
                      verifying and documenting the shipments with precision.
                    </li>

                    <li>
                      <strong>Detachment and Unloading:</strong> We handle the
                      detachment and unloading of the cargo with utmost care,
                      ensuring that goods are handled safely and efficiently.
                    </li>

                    <li>
                      <strong>Cargo Delivery:</strong> We go the extra mile by
                      delivering the cargo either to the representative of the
                      recipient organization or to the agreed-upon destination
                      point, as specified in the request.
                    </li>

                    <li>
                      <strong>Wagon (Track) Maintenance:</strong> We take
                      responsibility for cleaning and delivering the empty wagon
                      or track, ensuring it's ready for future use.
                    </li>

                    <li>
                      <strong>Wagon (Track) Operations:</strong> Our services
                      cover all necessary operations related to the wagon or
                      track, guaranteeing its functionality and compliance with
                      safety standards.
                    </li>
                  </ol>
                </p>
              </div>
            </div>
            <div className="col-lg-4 mt-5 mt-lg-0">
              <div className="mb-5">
                <h3
                  className="text-uppercase mb-4"
                  style={{ letterSpacing: "5px" }}
                >
                  Categories
                </h3>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                    <Link to="/Sea" className="text-decoration-none h6 m-0">
                      Sea Transportation
                    </Link>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                    <Link to="/Railway" className="text-decoration-none h6 m-0">
                      Railway Transportation
                    </Link>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                    <Link
                      to="/Container"
                      className="text-decoration-none h6 m-0"
                    >
                      Container Transportation
                    </Link>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                    <Link
                      to="/Multimodal"
                      className="text-decoration-none h6 m-0"
                    >
                      Multimodal Transportation
                    </Link>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                    <Link to="/Freight" className="text-decoration-none h6 m-0">
                      Freight Forwarding
                    </Link>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                    <Link to="/Trading" className="text-decoration-none h6 m-0">
                      Trading
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Detail End */}

      <Footer />
    </>
  );
}
